import path from 'path';

import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  filterMarkets,
  getCanonicalUrl,
  getHrefLanguages,
} from '@hultafors/shared/helpers';

import {
  datoApi,
  getContentAreaData,
  markets,
} from '@hultafors/hultaforsgroup/api';
import {
  ContentAreaData,
  GlobalFields,
  LandingPageContentFragment,
  PageProps,
} from '@hultafors/hultaforsgroup/types';

import { prodDomains } from '../next.domains';

const Page = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((module) => module.Page),
);

const ContentArea = dynamic(() =>
  import('@hultafors/hultaforsgroup/components').then((mod) => mod.ContentArea),
);

// Needed for standalone build, for some reason
path.resolve('./next.config.js');

type LandingPageProps = PageProps<LandingPageContentFragment>;

export const getStaticProps: GetStaticProps<LandingPageProps> = async ({
  locale,
}) => {
  const revalidate = 300; // 5 minutes
  try {
    const dato = datoApi(locale);
    const canonical = getCanonicalUrl(
      locale,
      prodDomains,
      'www.hultaforsgroup.com',
    );

    const {
      data: { landingPage: content, ...globalFields },
    } = await dato.getLandingPage();

    const hrefLanguages = getHrefLanguages(
      null,
      filterMarkets(markets, 'HULTAFORSGROUP'),
      content?.pageLocales,
    );

    const contentAreaData: ContentAreaData = await getContentAreaData(
      dato,
      content.contentArea,
    );

    const props: LandingPageProps = {
      canonical,
      content,
      contentAreaData,
      hrefLanguages,
      ...(globalFields as unknown as GlobalFields),
    };

    return { props, revalidate };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
      revalidate,
    };
  }
};

const LandingPage: NextPage<LandingPageProps> = ({
  canonical,
  content,
  hrefLanguages,
  contentAreaData,
}) => {
  return (
    <Page
      seo={content.seo}
      testId="landing-page"
      canonical={canonical}
      hrefLanguages={hrefLanguages}
    >
      <ContentArea content={content.contentArea} data={contentAreaData} />{' '}
    </Page>
  );
};

export default LandingPage;
